import React from "react"

import Landingpage from "../components/templates/landingpage"
import SEO from "../components/atoms/seo"

const NotFoundPage = () => (
  <Landingpage>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Landingpage>
)

export default NotFoundPage
